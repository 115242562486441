import CommonIcon from '@/components/common-icon';
import { useRouter, useTheme } from '@/core/hooks';
import { IGNORE_THEME_PATH } from '@/core/shared';
import { memo, useEffect, useState } from 'react';

const ThemeIcon = memo(({ className, iconActive }: { className?: string; iconActive: boolean }) => {
  const { theme, toggleTheme } = useTheme();
  const router = useRouter();
  const pathname = router.asPath;
  const [hover, setIsHover] = useState(false);
  let url = theme === 'dark' ? 'header-skin-white' : 'header-skin-grey-0';
  if (hover) {
    url = 'header-skin-active-0';
  }
  useEffect(() => {
    setIsHover(iconActive);
  }, [iconActive]);
  const isInCluded = IGNORE_THEME_PATH.find((item) => pathname?.match(item));
  if (isInCluded) return null;
  return (
    <CommonIcon
      enableSkin={hover}
      name={url}
      width={24}
      height={24}
      onClick={toggleTheme}
      className={className}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
});

export default ThemeIcon;
